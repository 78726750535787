	// -----------------------------------// 
	// FOOTER
	// -----------------------------------//

	footer {
		clear: both;
		position: relative;
		z-index: 99;
		margin: 0 auto;
		background: #222;
		border-top: 8px solid #fff;
		border-bottom: 8px solid #fff;
		height: 425px;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 0;
			margin: 0 auto;
		}

		.wrapper {
			text-align: left;
			font-size: 16px;
			width: 100%;
			font-weight: 600;

			@media only screen and (max-width: 990px) {
				width: 100%;
				padding: 0;
				text-align: center;
			}
		}

		p,
		a[href^="mailto:"] {
			margin: 0;
			color: #fff;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 10px;
				display: block;
				float: none;
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}

		.details,
		.trust-details,
		.map {
			display: inline-block;
			vertical-align: top;
			height: 100%;
			float: left;

			@media only screen and (max-width: 990px) {
				display: block;
				width: 100%;
				float: none;
			}
		}

		.details {
			width: 45%;
			background: #000;
			padding: 40px 0 0 19%;
			text-transform: uppercase;

			@media only screen and (max-width: 1800px) {
				padding: 40px 0 0 15%;
			}

			@media only screen and (max-width: 1500px) {
				padding: 40px 0 0 10%;
			}

			@media only screen and (max-width: 1350px) {
				padding: 40px 0 0 5%;
			}

			@media only screen and (max-width: 990px) {
				display: block;
				width: 100%;
				float: none;
				text-align: center;
				padding: 20px 5% 50px;
			}

			.address {
				margin: 0 0 20px 0;
				font-size: 15px;

				@media only screen and (max-width: 990px) {
					margin: 0 auto 30px;
				}

				img,
				span {
					display: inline-block;
					vertical-align: middle;

					@media only screen and (max-width: 990px) {
						display: block;
					}
				}

				img {
					margin: 0 20px 0 0;

					@media only screen and (max-width: 990px) {
						width: 76px;
						margin: 0 auto 10px;
					}
				}
			}

			.footer-btns {
				margin: 50px 0 0 0;

				@media only screen and (max-width: 990px) {
					margin: 50px auto 0;
				}

				a {
					clear: both;
					display: inline-block;
					margin: 0 auto;
					text-align: center;
					width: 40%;
					height: 50px;
					line-height: 50px;
					box-shadow: inset 0 0 0 3px #ffc700;
					color: #ffc700;

					@media only screen and (max-width: 990px) {
						display: block;
						width: 280px;
						margin: 0 auto;
					}

					&:hover {
						box-shadow: inset 0 0 0 3px rgba(#fff, 1), inset 0 0 0 30px rgba(#fff, .1);
						color: #fff;
					}

					&:nth-of-type(1) {
						margin: 0 10px 0 0;

						@media only screen and (max-width: 990px) {
							margin: 0 auto 10px;
						}
					}
				}
			}

			.cont-details {
				margin: 20px 0 0 0;
			}

			a:not(.footer-btns a):hover {
				color: #ffc700;
			}
		}

		.trust-details {
			background: #2e2e2e;
			width: 395px;
			padding: 50px 50px 0;
			text-transform: uppercase;

			@media only screen and (max-width: 990px) {
				display: block;
				width: 100%;
				float: none;
				text-align: center;
				padding: 30px 5%;
				margin: 0 auto;
			}

			img {
				margin: 0 0 40px 0;

				@media only screen and (max-width: 990px) {
					margin: 0 auto 30px;
				}
			}

			.trust-info {
				font-size: 12px;
				margin: 20px 0 0 0;
				text-transform: none;
				font-weight: normal;
				line-height: 1.2;
			}

			a:hover {
				color: #ffc700;
			}
		}

		.map {
			width: calc(100% - 45% - 395px);
			position: relative;

			@media only screen and (max-width: 990px) {
				display: none;
			}

			a {
				display: block;
				height: 100%;
				width: 100%;
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				transition: .3s;
			}

			.tooltip {
				@include center;
				top: 65%;
				border-radius: 5px;
				text-align: right;
				text-transform: uppercase;
				color: #fff;
				font-size: 12px;
				pointer-events: none;
				user-select: none;
				transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				opacity: 0;
				z-index: -1;
				white-space: nowrap;
				font-weight: 800;
				z-index: 999;
				background: $h1-color;
				padding: 20px 30px;
				border-radius: 50px;
				box-shadow: 0 0 0 2px #fff;

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}

			&:hover {
				img {
					filter: brightness(50%);
				}

				.tooltip {
					opacity: 1;
					top: 50%;
				}
			}
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.sub-footer {
		padding: 10px 0;
		background: #1e2949;
		overflow: hidden;
		@extend .clearfix;
		text-transform: uppercase;

		@media only screen and (max-width: 990px) {
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		.wrapper {
			width: 1200px;
			max-width: 95%;
		}

		p {
			font-size: 12px;
			color: #fff;
			margin: 0;
			font-weight: bold;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 5px auto;
			}
		}

		#copyright {
			float: left;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#credit {
			float: right;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#copyright,
		#credit {

			a {
				color: #fff;

				@media only screen and (max-width: 990px) {
					margin: 0;
					display: inline-block;
				}

				&:last-of-type {
					color: #9196be;
				}
			}
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 80px;
		right: 50px;
		display: block;
		@include box(45px);
		background: $h1-color;
		box-shadow: 0 0 0 3px #fff, 0 0 0 8px rgba(#fff, .2);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}