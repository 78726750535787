@media only screen and (max-width: 990px) {

	body {
		min-width: 300px;
		overflow-x: hidden;
	}

	.wrapper {
		width: 100%;
	}

	#mainholder {
		width: 100%;
		padding: 30px 0 0 0;
	}

	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 100%;
		padding: 30px 8%;
		margin: 0 auto 5%;
		float: none;
		display: block;
		box-sizing: border-box;
		border-radius: 0;
	}

	.ColumnLeft,
	.ColumnRight {
		width: 100%;
		padding: 20px 5%;
		margin: 5% auto;
		float: none;
		display: block;
		box-sizing: border-box;
	}

	#SideHeadingPages {
		padding: 0;
	}

	.modal-home {
		.message-modal {
			width: 90%;
			margin: 0 5%;
			left: 0;
			top: 20%;
			padding: 0;

			.modal-content {
				.modal-text {
					padding: 3%;
				}

				a.close-modal {
					position: absolute;
					top: 3%;
					right: 3%;
					background: #fff;
					padding: 3px;
					border-radius: 0px 0px 0px 7px;
				}
			}
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	table.tableborder {
		width: 100%;
		overflow: scroll;
		white-space: nowrap;
		table-layout: fixed;
		position: relative;

		&:before {
			content: "";
			width: 30px;
			height: 100%;
			position: absolute;
			top: 0;
			right: -1px;
			background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#33000000', GradientType=1);
		}

		tbody {
			width: 100%;
			float: left;
			white-space: normal;
			overflow-x: scroll;
		}
	}

	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}
}

// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

// @media only screen and (max-width: 1800px) {}
// @media only screen and (max-width: 1500px) {}
// @media only screen and (max-width: 1350px) {}
// @media only screen and (max-width: 990px) {}
// @media only screen and (max-width: 500px) {}
// @media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}